import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["list", "spinner"]

  connect() {
    $(this.element).on("show.bs.modal", event => { this.load() })
    $(this.element).on("hidden.bs.modal", event => { this.clear() })
  }

  load() {
    const headers = { Accept: "text/vnd.turbo-stream.html" }
    fetch(this.data.get("url"), { headers: headers })
      .then(response => response.text())
      .then(html => {
        this.spinnerTarget.hidden = true
        Turbo.renderStreamMessage(html)
      })
  }

  clear() {
    this.listTarget.innerHTML = null
    this.spinnerTarget.hidden = false
  }
}
