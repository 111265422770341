import { Controller } from "@hotwired/stimulus"
import consumer from "../../channels/consumer"

export default class extends Controller {
  static targets = ["icon"]

  connect() {
    this._connectCable()
  }

  disconnect() {
    this._disconnectCable()
  }

  _connectCable() {
    if (!this.notificationBellsChannel) {
      this.notificationBellsChannel = consumer.subscriptions.create({
        channel: "NotificationBellsChannel",
        workspace_id: this.data.get("workspaceId")
      }, {
        received: data => {
          this._replaceIcon(data)
        }
      });
    }
  }

  _disconnectCable() {
    if (this.notificationBellsChannel) {
      this.notificationBellsChannel.unsubscribe()
      delete this.notificationBellsChannel
    }
  }

  _replaceIcon(data) {
    this.iconTarget.innerHTML = data["html"]
  }
}
