import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "submitButton", "saved", "notSaved"]

  connect() {
    this.timer = null
    this.delay = 3000
  }

  submitNow() {
    clearTimeout(this.timer)
    this._submit()
  }

  submitLater() {
    this._markNotSaved()

    clearTimeout(this.timer)

    const submitFn = () => { this._submit() }
    this.timer = setTimeout(submitFn, this.delay)
  }

  _submit() {
    this.submitButtonTarget.click()
  }

  _markNotSaved() {
    this.savedTarget.hidden = true
    this.notSavedTarget.hidden = false
  }
}
