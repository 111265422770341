import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["teamSelect", "refreshButton", "checkbox"]

  connect() {
    const chosenOptions = { no_results_text: window.settings.chosen_no_results }
    $(this.teamSelectTarget).chosen(chosenOptions).change(this._refreshForm.bind(this))
  }

  disconnect() {
    $(this.teamSelectTarget).chosen("destroy")
  }

  selectAll() {
    this.checkboxTargets.forEach(checkboxTarget => {
      checkboxTarget.checked = true
    })
  }

  deselectAll() {
    this.checkboxTargets.forEach(checkboxTarget => {
      checkboxTarget.checked = false
    })
  }

  _refreshForm() {
    this.refreshButtonTarget.click()
  }
}
