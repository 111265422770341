import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate"

import { Slovak } from "flatpickr/dist/l10n/sk.js"
import { Ukrainian } from "flatpickr/dist/l10n/uk.js"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    let locale = null
    switch(window.settings.locale) {
      case 'sk':
        locale = Slovak
        break
      case 'uk':
        locale = Ukrainian
        break
    }

    const enableTime = this.data.get("enableTime") !== "false"

    this.picker = flatpickr(this.inputTarget, {
      enableTime: enableTime,
      time_24hr: true,
      static: true,
      dateFormat: enableTime ? "Y-m-d H:i" : "Y-m-d",
      hourIncrement: 1,
      minuteIncrement: 1,
      locale: locale,

      plugins: [
        new confirmDatePlugin({
          confirmText: ""
        })
      ],

      // workaround for clearing field
      onChange: function(selectedDates, dateStr, instance){
        if (dateStr === '') { instance.close(); }
      }
    })
  }

  disconnect() {
    if (this.picker) {
      this.picker.destroy()
    }
  }
}
