import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).chosen({
      no_results_text: window.settings.chosen_no_results
    })
  }

  disconnect() {
    $(this.element).chosen("destroy")
  }
}
