import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "form", "actions"]

  showForm(event) {
    event.preventDefault()
    this.contentTarget.hidden = true
    this.actionsTarget.hidden = true
    this.formTarget.hidden = false
  }

  hideForm(event) {
    event.preventDefault()
    this.contentTarget.hidden = false
    this.actionsTarget.hidden = false
    this.formTarget.hidden = true
  }
}