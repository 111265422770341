import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]

  connect() {
    if (this.hasFieldTarget) {
      this.fieldTarget.focus()

      const valueLength = this.fieldTarget.value.length
      this.fieldTarget.selectionEnd = valueLength
      this.fieldTarget.selectionStart = valueLength
    }
  }
}
