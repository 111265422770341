import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "intro"]

  connect() {
    this._hideForm()
  }

  showForm(event) {
    event.preventDefault()
    this._showForm()
    this._hideIntro()
  }

  hideForm(event) {
    event.preventDefault()
    this._hideForm()
  }

  _showForm() {
    this.formTarget.hidden = false
  }

  _hideForm() {
    this.formTarget.hidden = true
  }

  _hideIntro() {
    if (this.hasIntroTarget) {
      this.introTarget.hidden = true
    }
  }
}
