import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [
    "scroll", "thread", "message", "olderMessagesLink", "olderMessagesSpinner", "form"
  ]

  connect() {
    this._scrollToBottom()
    this._toggleOlderMessagesLink()
    this._connectCable()
  }

  disconnect() {
    this._disconnectCable()
  }

  getOlderMessages(event) {
    event.preventDefault()
    this._showOlderMessagesSpinner()
    this._loadOldMessages()
  }

  _connectCable() {
    if (!this.conversationMessagesChannel) {
      this.conversationMessagesChannel = consumer.subscriptions.create({
        channel: "ConversationMessagesChannel",
        workspace_id: this.data.get("workspaceId"),
        id: this.data.get("id")
      }, {
        received: data => {
          this._replaceOrAppendMessage(data)
          this.conversationMessagesChannel.perform('message_read', {
            id: data["id"]
          })
        },
      })
    }
  }

  _disconnectCable() {
    if (this.conversationMessagesChannel) {
      this.conversationMessagesChannel.unsubscribe()
      delete this.conversationMessagesChannel
    }
  }

  _showOlderMessagesSpinner() {
    this.olderMessagesLinkTarget.hidden = true
    this.olderMessagesSpinnerTarget.hidden = false
  }

  _toggleOlderMessagesLink() {
    this.olderMessagesSpinnerTarget.hidden = true
    const firstMessage = this._firstMessage()
    if (!firstMessage || firstMessage.dataset.first === "true") {
      this.olderMessagesLinkTarget.hidden = true
    } else {
      this.olderMessagesLinkTarget.hidden = false
    }
  }

  _firstMessage() {
    if (this.messageTargets.length > 0) {
      return this.messageTargets[0]
    } else {
      return null
    }
  }

  _lastMessage() {
    if (this.messageTargets.length > 0) {
      return this.messageTargets[this.messageTargets.length - 1]
    } else {
      return null
    }
  }

  _loadOldMessages() {
    let url = this.data.get("oldMessagesUrl")
    const firstMessage = this._firstMessage()
    if (firstMessage) {
      url = url + "?before_id=" + firstMessage.dataset.id
    }

    fetch(url, { headers: { Accept: "text/html" } })
      .then(response => response.text())
      .then(html => {
        this._saveScrollOffset()
        this.threadTarget.insertAdjacentHTML('afterbegin', html)
        this._toggleOlderMessagesLink()
        this._applyBottomScrollOffset()
      })
  }

  _replaceOrAppendMessage(data) {
    let replaced = false
    this.messageTargets.forEach(messageTarget => {
      if (messageTarget.dataset.id == data["id"]) {
        this._saveScrollOffset()
        messageTarget.outerHTML = data["html"]
        this._applyTopScrollOffset()
        replaced = true
      }
    })

    if (!replaced) { // apend
      this._saveScrollOffset()
      this.threadTarget.insertAdjacentHTML('beforeend', data["html"])
      this._applyTopScrollOffset()
    }
  }

  _saveScrollOffset() {
    this.scrollTopOffset = this.scrollTarget.scrollTop
    this.scrollBottomOffset = Math.max(0, this._scrollHeight() - this._visibleHeight() - this.scrollTopOffset)
  }

  _applyTopScrollOffset() {
    if (this.scrollBottomOffset < 10) {
      this._scrollToBottom()
    } else {
      this.scrollTarget.scrollTop = this.scrollTopOffset
    }
  }

  _applyBottomScrollOffset() {
    this.scrollTarget.scrollTop = this._scrollHeight() - this._visibleHeight() - this.scrollBottomOffset
  }

  _scrollToBottom() {
    this.scrollTarget.scrollTop = this.scrollTarget.scrollHeight
  }

  _scrollHeight() {
    return this.scrollTarget.scrollHeight
  }

  _visibleHeight() {
    return this.scrollTarget.getBoundingClientRect().height
  }
}
