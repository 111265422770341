import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "textForm", "imageForm", "videoForm", "audioForm", "documentForm", "fileForm",
    "linkForm", "assignmentForm"
  ]

  connect() {
    this._hideAllForms()
  }

  showTextForm(event) {
    event.preventDefault()
    this._showForm(this.textFormTarget)
  }

  hideTextForm(event) {
    event.preventDefault()
    this._hideForm(this.textFormTarget)
  }

  showImageForm(event) {
    event.preventDefault()
    this._showForm(this.imageFormTarget)
  }

  hideImageForm(event) {
    event.preventDefault()
    this._hideForm(this.imageFormTarget)
  }

  showVideoForm(event) {
    event.preventDefault()
    this._showForm(this.videoFormTarget)
  }

  hideVideoForm(event) {
    event.preventDefault()
    this._hideForm(this.videoFormTarget)
  }

  showAudioForm(event) {
    event.preventDefault()
    this._showForm(this.audioFormTarget)
  }

  hideAudioForm(event) {
    event.preventDefault()
    this._hideForm(this.audioFormTarget)
  }

  showDocumentForm(event) {
    event.preventDefault()
    this._showForm(this.documentFormTarget)
  }

  hideDocumentForm(event) {
    event.preventDefault()
    this._hideForm(this.documentFormTarget)
  }

  showFileForm(event) {
    event.preventDefault()
    this._showForm(this.fileFormTarget)
  }

  hideFileForm(event) {
    event.preventDefault()
    this._hideForm(this.fileFormTarget)
  }

  showLinkForm(event) {
    event.preventDefault()
    this._showForm(this.linkFormTarget)
  }

  hideLinkForm(event) {
    event.preventDefault()
    this._hideForm(this.linkFormTarget)
  }

  showAssignmentForm(event) {
    event.preventDefault()
    this._showForm(this.assignmentFormTarget)
  }

  hideAssignmentForm(event) {
    event.preventDefault()
    this._hideForm(this.assignmentFormTarget)
  }

  _showForm(target) {
    this._hideAllForms()
    target.hidden = false
  }

  _hideForm(target) {
    target.hidden = true
  }

  _hideAllForms() {
    this._hideForm(this.textFormTarget)
    this._hideForm(this.imageFormTarget)
    this._hideForm(this.videoFormTarget)
    this._hideForm(this.audioFormTarget)
    this._hideForm(this.documentFormTarget)
    this._hideForm(this.fileFormTarget)
    this._hideForm(this.linkFormTarget)
    this._hideForm(this.assignmentFormTarget)
  }
}
