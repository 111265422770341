import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["questionTypeSelect", "correctAnswers"]

  connect() {
    this.updateFields()
  }

  updateFields() {
    this.correctAnswersTarget.hidden = this.questionTypeSelectTarget.value !== "fill_in_blank"
  }
}
