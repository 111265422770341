import { Controller } from "@hotwired/stimulus"
import consumer from "../../channels/consumer"

// TODO: Fix disabling completeButton

export default class extends Controller {
  static targets = ["questionContent", "optionContent", "completeButton"]

  connect() {
    this.answeringIds = []
    this._connectCable()
  }

  disconnect() {
    this._disconnectCable()
  }

  answerSend(event) {
    const questionId = event.target.dataset.questionId
    if (questionId) {
      this.answeringIds.push(questionId)
      this._toggleCompleteButton()
    }
  }

  answerComplete(event) {
    const questionId = event.target.dataset.questionId
    if (questionId) {
      this.answeringIds = this.answeringIds.filter(id => id !== questionId)
      this._toggleCompleteButton()
    }
  }

  _toggleCompleteButton() {
    this.completeButtonTarget.disabled = (this.answeringIds.length > 0)
  }

  _connectCable() {
    if (!this.testExaminationChannel) {
      this.testExaminationChannel = consumer.subscriptions.create({
        channel: "TestExaminationChannel",
        workspace_id: this.data.get("workspaceId"),
        id: this.data.get("id")
      }, {
        received: data => {
          switch (data["event"]) {
            case "question_updated":
              this._replaceQuestionContent(data)
              break
            case "question_option_updated":
              this._replaceOptionContent(data)
              break
          }
        },
      })
    }
  }

  _disconnectCable() {
    if (this.testExaminationChannel) {
      this.testExaminationChannel.unsubscribe()
      delete this.testExaminationChannel
    }
  }

  _replaceQuestionContent(data) {
    this.questionContentTargets.forEach(questionContentTarget => {
      if (questionContentTarget.dataset.id == data["id"]) {
        questionContentTarget.innerHTML = data["html"]
      }
    })
  }

  _replaceOptionContent(data) {
    this.optionContentTargets.forEach(optionContentTarget => {
      if (optionContentTarget.dataset.id == data["id"]) {
        optionContentTarget.innerHTML = data["html"]
      }
    })
  }
}
