import { Controller } from "@hotwired/stimulus"
import consumer from "../../channels/consumer"

export default class extends Controller {
  static targets = ["lessonElement"]

  connect() {
    this._connectCable()
  }

  disconnect() {
    this._disconnectCable()
  }

  _connectCable() {
    if (!this.courseStudyChannel) {
      this.courseStudyChannel = consumer.subscriptions.create({
        channel: "CourseStudyChannel",
        workspace_id: this.data.get("workspaceId"),
        id: this.data.get("id")
      }, {
        received: data => {
          switch (data["event"]) {
            case "lesson_element_updated":
              this._replaceLessonElement(data)
              break
          }
        },
      })
    }
  }

  _disconnectCable() {
    if (this.courseStudyChannel) {
      this.courseStudyChannel.unsubscribe()
      delete this.courseStudyChannel
    }
  }

  _replaceLessonElement(data) {
    this.lessonElementTargets.forEach(lessonElementTarget => {
      if (lessonElementTarget.dataset.id == data["id"]) {
        lessonElementTarget.outerHTML = data["html"]
      }
    })
  }
}
