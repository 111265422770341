import { Controller } from "@hotwired/stimulus"
import consumer from "../../channels/consumer"

export default class extends Controller {
  static targets = ["lessonElement", "lockAlert"]

  connect() {
    this._connectCable()
    this._startPulse()
  }

  disconnect() {
    this._stopPulse()
    this._disconnectCable()
  }

  _connectCable() {
    if (!this.courseBuilderChannel) {
      this.courseBuilderChannel = consumer.subscriptions.create({
        channel: "CourseBuilderChannel",
        workspace_id: this.data.get("workspaceId"),
        id: this.data.get("id")
      }, {
        received: data => {
          switch (data["event"]) {
            case "lesson_element_updated":
              this._replaceLessonElement(data)
              break
            case "lock_updated":
              this._updateLockAlert(data)
              break
          }
        },
      })
    }
  }

  _disconnectCable() {
    if (this.courseBuilderChannel) {
      this.courseBuilderChannel.unsubscribe()
      delete this.courseBuilderChannel
    }
  }

  _replaceLessonElement(data) {
    this.lessonElementTargets.forEach(lessonElementTarget => {
      if (lessonElementTarget.dataset.id == data["id"]) {
        lessonElementTarget.outerHTML = data["html"]
      }
    })
  }

  _updateLockAlert(data) {
    if (data["locks_count"] > 1) {
      this.lockAlertTarget.classList.add("shown")
    } else {
      this.lockAlertTarget.classList.remove("shown")
    }
  }

  _startPulse() {
    this.pulseTimer = setInterval(() => {
      if (this.courseBuilderChannel) {
        this.courseBuilderChannel.perform("pulse")
      }
    }, 10000)
  }

  _stopPulse() {
    if (this.pulseTimer) {
      clearInterval(this.pulseTimer)
    }
  }
}
