import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.maxMb = parseInt(this.data.get("maxMb"))
    this.maxMb = this.maxMb || 0
  }

  checkSize() {
    if (this.maxMb <= 0) { return }
    const maxBytes = this.maxMb * 1024 * 1024
    const files = this.element.files
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxBytes) {
        alert(`${window.settings.file_is_too_big} (max ${this.maxMb} MB)`)
        this.element.value = ""
        break
      }
    }
  }
}
