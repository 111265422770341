import { Controller } from "@hotwired/stimulus"
import { Draggable } from '@shopify/draggable'

export default class extends Controller {
  connect() {
    this.draggedItem = null
    this.draggedOverItem = null

    const key = this.data.get("key")

    this.draggable = new Draggable(this.element, {
      draggable: `[data-position-item~="${key}"]`,
      handle: `[data-position-dragger~="${key}"]`,
      delay: 200,
      mirror: { constrainDimensions: true },
    })

    this.draggable.on('drag:start', event => this._dragStart(event))
    this.draggable.on('drag:over',  event => this._dragOver(event))
    this.draggable.on('drag:out',   event => this._dragOut(event))
    this.draggable.on('drag:stop',  event => this._dragStop(event))
  }

  disconnect() {
    if (this.draggable) {
      this.draggable.destroy()
    }
  }

  _dragStart(event) {
    this.draggedItem = event.data.source
    this.draggedOverItem = null
  }

  _dragOver(event) {
    this.draggedOverItem = event.data.over
  }

  _dragOut(event) {
    this.draggedOverItem = null
  }

  _dragStop(event) {
    if (this.draggedItem && this.draggedOverItem) {
      const oldPosition = this.draggedItem.dataset.position
      const newPosition = this.draggedOverItem.dataset.position
      if (oldPosition !== newPosition) {
        const url = this.draggedItem.dataset.positionUrl
        this._updatePosition(url, newPosition)
      }
    }
    this.draggedItem = null
    this.draggedOverItem = null
  }

  _updatePosition(url, position) {
    const csrfToken = document.querySelector("[name='csrf-token']").content

    const formData = new FormData()
    formData.append('position', position)

    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": csrfToken,
      },
      body: formData
    }).then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
