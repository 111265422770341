import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "radio", "submitButton"]

  submit() {
    this._toggleDisabled(true)
    this.submitButtonTarget.click()
  }

  _toggleDisabled(disable) {
    this.radioTargets.forEach(radio => {
      if (disable) {
        radio.disabled = !radio.checked
      } else {
        radio.disabled = false
      }
    })
  }
}
