import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "date"]

  connect() {
    this.toggleDateInput()
  }

  toggleDateInput() {
    this.dateTarget.hidden = !this.checkboxTarget.checked
  }
}