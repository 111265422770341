// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "./turbo_stream_actions"

import "@fortawesome/fontawesome-free/js/solid"
import "@fortawesome/fontawesome-free/js/regular"
import "@fortawesome/fontawesome-free/js/brands"
import "@fortawesome/fontawesome-free/js/fontawesome"
FontAwesome.config.mutateApproach = 'sync'

import "trix"
document.addEventListener("trix-file-accept", e => e.preventDefault())

import "./jquery"

import "chosen-js"

import "bootstrap/js/dist/util"
import "bootstrap/js/dist/modal"
import "bootstrap/js/dist/dropdown"

import "chartkick/chart.js"

// import "./channels"
import "./controllers"
