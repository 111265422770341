import { Controller } from "@hotwired/stimulus"
import Plyr from "plyr"

export default class extends Controller {
  static targets = ["item"]

  connect() {
    this.player = new Plyr(this.itemTarget, {
      settings: []
    })
  }

  disconnect() {
    if (this.player) {
      this.player.destroy()
    }
  }
}
