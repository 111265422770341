import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.dangerClass = "text-danger"
    this.dangerDelay = 60*3

    this.startTime = new Date()
    this.seconds = parseInt(this.data.get("seconds"))
    this.interval = setInterval(this._tick.bind(this), 1000)
    this._updateOutput(this.seconds)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  _updateOutput(seconds) {
    this.element.innerHTML = this._formattedDuration(seconds)

    if (seconds < this.dangerDelay) {
      this.element.classList.add(this.dangerClass)
    } else {
      this.element.classList.remove(this.dangerClass)
    }
  }

  _secondsRemaining() {
    const endTime = new Date()
    const millisecondsElapsed = endTime.getTime() - this.startTime.getTime()
    const secondsElapsed = Math.round(millisecondsElapsed / 1000)
    return this.seconds - secondsElapsed
  }

  _tick() {
    const remaining =  this._secondsRemaining()
    this._updateOutput(remaining)

    if (remaining < 0) {
      Turbo.visit(window.location.href)
    }
  }

  _formattedDuration(secondsTotal) {
    const secondsToFormat = Math.max(0, secondsTotal)

    let hours   = Math.floor(secondsToFormat / 3600)
    let minutes = Math.floor((secondsToFormat - hours * 3600) / 60)
    let seconds = secondsToFormat - hours * 3600 - minutes * 60

    if (hours   < 10) { hours   = "0" + hours }
    if (minutes < 10) { minutes = "0" + minutes }
    if (seconds < 10) { seconds = "0" + seconds }

    return `${hours}:${minutes}:${seconds}`
  }
}
