import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "input", "inputCache", "name"]

  connect() {
    this._refreshName()
  }

  browse(event) {
    event.preventDefault()
    this.inputTarget.click()
  }

  changeInput() {
    this._refreshName()
  }

  _refreshName() {
    const maxLength = 10

    let showName = null
    if (this.inputTarget.value.length > 0) {
      showName = this._getFileNameFromInput(this.inputTarget)
    } else if (this.inputCacheTarget && this.inputCacheTarget.dataset.filename) {
      showName = this.inputCacheTarget.dataset.filename
    } else {
      showName = ""
    }

    if (showName && showName.length > maxLength) {
      showName = showName.substring(0, maxLength - 3) + "&hellip;"
    }

    this.nameTarget.innerHTML = showName
  }

  _getFileNameFromInput(input) {
    const names = []
    for (let i = 0; i < input.files.length; i++) {
      names.push(input.files[i].name)
    }
    return names.join(",")
  }
}