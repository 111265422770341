import { Controller } from "@hotwired/stimulus"
import consumer from "../../channels/consumer"

export default class extends Controller {
  static targets = ["submission"]

  connect() {
    this._connectCable()
  }

  disconnect() {
    this._disconnectCable()
  }

  _connectCable() {
    if (!this.lessonAssignmentSubmissionChannel) {
      this.lessonAssignmentSubmissionChannel = consumer.subscriptions.create({
        channel: "LessonAssignmentSubmissionChannel",
        workspace_id: this.data.get("workspaceId"),
        id: this.data.get("id")
      }, {
        received: data => {
          switch (data["event"]) {
            case "submission_updated":
              this._replaceSubmission(data)
              break
          }
        },
      })
    }
  }

  _disconnectCable() {
    if (this.lessonAssignmentSubmissionChannel) {
      this.lessonAssignmentSubmissionChannel.unsubscribe()
      delete this.lessonAssignmentSubmissionChannel
    }
  }

  _replaceSubmission(data) {
    this.element.innerHTML = data["html"]
  }
}
