import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item"]

  connect() {
    this._scrollToActiveItem()
  }

  _scrollToActiveItem() {
    const itemToCenter = this.itemTargets.find(itemTarget => (
      itemTarget.classList.contains("active")
    ))

    if (itemToCenter) this._centerItem(itemToCenter)
  }

  _centerItem(itemTarget) {
    const stripWidth = this.element.scrollWidth
    const visibleWidth = this.element.getBoundingClientRect().width
    if (stripWidth > visibleWidth) {
      const itemWidth = itemTarget.getBoundingClientRect().width
      const itemLeft = itemTarget.offsetLeft
      this.element.scrollLeft = itemLeft + (itemWidth / 2) - (visibleWidth / 2)
    }
  }
}