import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form", "previewField", "teamSelect", "courseSelect", "lessonSelect",
    "submitButton"
  ]

  connect() {
    const chosenOptions = {
      no_results_text: window.settings.chosen_no_results,
      allow_single_deselect: true
    }

    $(this.teamSelectTarget).chosen(chosenOptions).change(this._selectChange.bind(this))
    $(this.courseSelectTarget).chosen(chosenOptions).change(this._selectChange.bind(this))
    $(this.lessonSelectTarget).chosen(chosenOptions)
  }

  disconnect() {
    $(this.teamSelectTarget).chosen("destroy")
    $(this.courseSelectTarget).chosen("destroy")
    $(this.lessonSelectTarget).chosen("destroy")
  }

  updatePreview() {
    this._selectChange()
  }

  _selectChange() {
    this.previewFieldTarget.value = "true"
    this.submitButtonTarget.click()
  }
}